<template>
  <div class="mx-auto max-w-7xl sm:text-center lg:text-left">
    <main class="mx-auto mt-8 px-4 sm:px-6 md:mt-12 lg:mt-10 lg:pl-8 xl:mt-16">
      <h1
        class="text-3xl font-extrabold tracking-tight sm:text-4xl md:text-5xl"
      >
        <span class="block text-blue-ribbon-500 xl:inline"> 404 </span>
        <span class="block text-gray-900 xl:inline"> Page not found. </span>
      </h1>
      <div class="pr-0 text-xl">
        The page you tried to access doesn't exist.
      </div>
      <div class="mt-5 flex justify-center">
        <div class="mt-3 sm:mt-0 sm:ml-3">
          <router-link
            :to="{ name: 'Home' }"
            class="flex w-full items-center justify-center rounded-md border border-transparent bg-blue-ribbon-50 px-8 py-3 text-base font-medium text-blue-ribbon-600 hover:bg-blue-ribbon-100 md:py-4 md:px-10 md:text-lg"
          >
            Home
          </router-link>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
import { useHead } from '@vueuse/head'

useHead({
  title: `Page not found - GaragesNear.me`,
  meta: [
    {
      name: 'og:title',
      content: 'Page not found - GaragesNear.me'
    },
    {
      name: 'description',
      content: 'Compare and book garage services online'
    },
    {
      name: 'og:description',
      content: 'Compare and book garage services online'
    },
    {
      name: 'og:image',
      content: '/open-graph/hero.webp'
    },
    {
      name: 'og:image:alt',
      content: 'GaragesNear.me banner'
    },
    {
      name: 'og:image:width',
      content: 1280
    },
    {
      name: 'og:image:height',
      content: 576
    }
  ]
})
</script>
